import React, { RefObject } from "react"
import moment from "moment"
import { AutoComplete, Button, Col, DatePicker, Form, Input, InputNumber, Modal, Radio, Row, Select, Spin } from "antd"
import ReceiptVoucherCreateCommand from "../../Command/ReceiptVoucherCreateCommand"
import ProjectDomain from "../../Domain/ProjectDomain"
import CustomerDomain from "../../Domain/CustomerDomain"
import lodash from "lodash"
import ProjectApi from "../../Api/ProjectApi"
import SuggestCustomerQueryCriteria from "../../QueryCriteria/SuggestCustomerQueryCriteria"
import CustomerApi from "../../Api/CustomerApi"
import Utils from "../../Util/Utils"
import { AppContext } from "../../Context/AppContext"
import AccountDomain from "../../Domain/AccountDomain"
import { DraggableModal, DraggableModalRef } from "../DraggableModal"
import SecurityService from "../../Util/SecurityService";
import { ACCOUNT_PERMISSIONS } from "../../Util/Constants";
import { ReceiptVoucherDetails } from "../../Domain/ReceiptVoucherDomain"
import ReceiptVoucherApi from "../../Api/ReceiptVoucher"
import { ModalCommon } from "../ModalCommon";
import { NotificationCommon } from "../Notification"

interface Props {
    modalRef?: RefObject<DraggableModalRef>
    loading?: boolean
    voucherCloneCode?: string
    onVisibleChange: (visible: boolean) => void
    onSubmit: (form: ReceiptVoucherCreateCommand) => void
}

interface State {
    account: AccountDomain
    loading: boolean
    payerType: string
    form: ReceiptVoucherCreateCommand
    projects: Array<ProjectDomain>
    customers: Array<CustomerDomain>
    project: any
    deliveryNotes: any[]
    deliveryNoteProject: any
    loadingDeliveryNotes: boolean
    deliveryNoteNotFound: boolean
}
const maxHeight: number = (window.innerHeight / 100) * 85
const innerHeight: number = maxHeight > 512 ? 680 : maxHeight

class ModalReceiptVoucherCreate extends React.Component<Props, State> {
    static contextType = AppContext
    formRef: any | undefined = React.createRef()

    state: State = {
        account: Utils.getAppContext(this).state.account,
        loading: false,
        payerType: "system",
        form: {
            timestamp: moment().toISOString(),
            account: lodash.get(Utils.getAppContext(this).state.account, "code"),
            project: '',
            type: 'CUSTOMER'
        },
        projects: [],
        customers: [],
        project: undefined,
        deliveryNotes: [],
        deliveryNoteProject: undefined,
        loadingDeliveryNotes: false,
        deliveryNoteNotFound: false
    }

    static getDerivedStateFromProps(props: any, state: any) {
        const newState = { ...state }
        if ("loading" in props) {
            newState.loading = props.loading
        }
        return newState
    }

    componentDidMount() {
        this.fetchProjects()

        const { voucherCloneCode } = this.props

        if (voucherCloneCode) {
            this.fetchVoucherDetails(voucherCloneCode)
        }
    }

    fetchVoucherDetails = (code: string) => {
        this.setState({ loading: true })
        ReceiptVoucherApi.getByCode(code)
            .then((response) => {
                this.initialFormValues(response.data)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    initialFormValues = (values: ReceiptVoucherDetails) => {
        const isHadPayerRef = values.project
        this.setState({
            payerType: isHadPayerRef ? 'system' : 'person',
            project: values.project,
            form: {
                ref: values.ref,
                timestamp: moment().toISOString(),
                memo: values.memo,
                remark: values.remark,
                payerRef: isHadPayerRef && values.payerRef?.username,
                payer: isHadPayerRef ? '' : values.payer,
                project: values.project,
                type: values.type,
                orderCode: values.orderCode,
                deliveryNote: values.deliveryNote,
            },
            deliveryNoteProject: values.project
        })
        this.formRef?.current?.setFieldsValue({ memo: values.memo })
    }

    fetchProjects = (filter = {}) => {
        this.setState({ loading: true })
        filter = {
            ...filter,
            offset: 0,
            limit: 1000,
        }
        ProjectApi.filter(filter)
            .then((response) => {
                this.setState({
                    projects: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    fetchCustomers = (filter: SuggestCustomerQueryCriteria = {}) => {
        this.setState({ loading: true })
        CustomerApi.getSuggestCustomers({ limit: "200", ...filter })
            .then((response) => {
                this.setState({
                    customers: response.data,
                })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    handleChangeInput = (field: string, e: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: e.target.value,
            },
        })
    }

    handleChangeNumber = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value,
            },
        })
    }

    handleChangeSelect = (field: string, value: any) => {
        if (['type'].includes(field)) {
            this.setState({
                deliveryNoteNotFound: false,
                deliveryNoteProject: undefined,
                form: {
                    ...this.state.form,
                    deliveryNote: undefined,
                    orderCode: undefined,
                }
            })
        }
        this.setState({
            form: {
                ...this.state.form,
                [field]: field === 'payerRef' ? value?.trim() : value,
            },
        })
    }

    handleChangeDatePicker = (field: string, value: any) => {
        this.setState({
            form: {
                ...this.state.form,
                [field]: value ? value.toISOString() : null,
            },
        })
    }

    handleChangePayerType = (e: any) => {
        const { form } = this.state

        if (!form.deliveryNote) {
            this.setState({
                payerType: e.target.value,
                project: undefined,
                form: {
                    ...form,
                    payerRef: '',
                    payer: '',
                    project: undefined,
                }
            })
        } else {
            this.setState({
                payerType: e.target.value,
                form: {
                    ...form,
                    payerRef: '',
                    payer: '',
                }
            })
        }
    }

    handleChangeProject = (value: any, isDeliveryNoteProject?: boolean) => {

        if (!isDeliveryNoteProject) {
            this.setState({
                project: value,
            })
        } else {
            this.setState({
                deliveryNoteProject: value,
            })
        }

        const { form } = this.state
        form.payerRef = ""
        this.setState({
            form: { ...form, project: value },
            customers: [],
        })
    }

    handleSearchStaff = (value: any) => {
        if (value && value.toString().length >= 3) {
            this.fetchCustomers({
                query: value,
                project: this.state.project,
            })
        }
    }

    handleWarningDeliveryNoteNotFound = (data: any) => {
        Modal.confirm({
            centered: true,
            title: 'Cảnh báo',
            content: 'Không tìm thấy mã phiếu xuất trong hệ thống được chọn. Bạn có muốn tiếp tục tạo phiếu thu?',
            okText: 'Xác nhận',
            cancelText: 'Huỷ bỏ',
            onOk: () => {
                this.setState({ deliveryNoteNotFound: false })
                this.props.onSubmit(data)
            }
        });
    }

    handleSubmit = (isSubmidAndConfirm?: boolean) => {
        const { form, payerType, customers, project, account, deliveryNoteProject, deliveryNoteNotFound } = this.state
        const data = { approve: isSubmidAndConfirm, account: account.code, ...form }
        if (payerType === "person") {
            lodash.unset(data, "payerRef")
        } else {
            lodash.unset(data, "payer")
            const customer = customers.find((c) => c.username === data.payerRef)
            if (project && data.payerRef) {
                data.payerRef = `c:${project}:${data.payerRef}`
            }
            if (customer) {
                data.payer = `${customer.fullname} (${customer.project})`
            }
        }

        if (deliveryNoteProject && data.project !== deliveryNoteProject) {
            NotificationCommon.error({
                key: 'project_not_same',
                message: 'Hệ thống của người nộp không đúng với hệ thống cấp phiếu xuất!'
            })
            return
        }

        if (data.type === 'ORDER' && (!data.project || !deliveryNoteProject)) {
            NotificationCommon.error({
                key: 'project_not_empty',
                message: 'Hệ thống không được bỏ trống'
            })
            return
        }

        if (!!data.payerRef) {
            this.checkUsernameExist(data, isSubmidAndConfirm)
            return
        }

        else {
            if (deliveryNoteNotFound && form.deliveryNote) {
                this.handleWarningDeliveryNoteNotFound({
                    ...data,
                    project: data.project || deliveryNoteProject
                })
            } else
                this.props.onSubmit({
                    ...data,
                    project: data.project || deliveryNoteProject
                })
        }
    }

    checkUsernameExist = (data: any, isSubmitAndConfirm?: boolean) => {
        const { customers, form, deliveryNoteNotFound } = this.state
        const userSelectedIndex: any = customers.findIndex((customer) => customer.username === data.payerRef.split(':')[2]);
        console.log('userSelectedIndex', userSelectedIndex)
        if (userSelectedIndex !== -1) {
            if (deliveryNoteNotFound && form.deliveryNote) {
                console.log('vao day')
                this.handleWarningDeliveryNoteNotFound(data)
                return
            }
            else {
                this.props.onSubmit(data)
                return
            }
        }

        if (userSelectedIndex === -1 && !isSubmitAndConfirm) {
            ModalCommon.confirm({
                title: <span>Username <span className={'bold'}>{form.payerRef}</span> không tồn tại trong hệ thống <span className={'bold'}>{form.project}</span>. Bạn vẫn muốn tiếp tục?</span>,
                onOk: () => {
                    this.props.onSubmit(data)
                },
            })
        } else {
            if (!deliveryNoteNotFound && form.deliveryNote)
                this.handleWarningDeliveryNoteNotFound(data)
            else
                this.props.onSubmit(data)

        }

    }

    handleCancel = () => {
        this.props.onVisibleChange(false)
    }

    fetchDeliveryNote = async (code: string) => {
        this.setState({ loadingDeliveryNotes: true })
        const searchParamsTemp = {
            code,
            project: this.state.deliveryNoteProject
        }
        try {
            const response = await ReceiptVoucherApi.getDeliveryNote(searchParamsTemp)
            const data = await response.data
            if (data && Array.isArray(data) && data.length === 0) {
                this.setState({ deliveryNoteNotFound: true })
            }
            this.setState({ deliveryNotes: response.data, loadingDeliveryNotes: false })
        } catch (error) {
            this.setState({ deliveryNoteNotFound: true, loadingDeliveryNotes: false })

        }
    }

    handleSearchDeliveryNote = (value: any) => {
        if (value && value.toString().length >= 3 && this.state.deliveryNoteProject) {
            this.fetchDeliveryNote(value)
        }
    }

    componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>, _snapshot?: any): void {
        if (prevState.deliveryNoteProject !== this.state.deliveryNoteProject) {
            this.fetchDeliveryNote('')
        }
    }

    render(): React.ReactNode {
        const form: ReceiptVoucherCreateCommand = this.state.form
        const { loading, payerType, customers, projects, project, account, loadingDeliveryNotes } = this.state

        const currency = lodash.get(account, "currency.code")
        const accountType = lodash.get(account, "type")
        const accountProject = account.projects || []

        return (
            <DraggableModal
                ref={this.props.modalRef}
                title="Tạo phiếu thu"
                initialHeight={innerHeight}
                footer={<>
                    <Button key="back"
                        tabIndex={12}
                        disabled={loading}
                        icon={<i className="fa-solid fa-xmark pd-r-8" />}
                        type={"ghost"}
                        onClick={this.handleCancel}
                        className="sm-mg-t-8"
                    >
                        Hủy Bỏ
                    </Button>
                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_APPROVE) &&
                        <Button key="submitAndApprove"
                            className="sm-mg-t-8"
                            tabIndex={11}
                            disabled={loading || form.amount === undefined || form.amount === null || !form.memo}
                            icon={<i className="fa-solid fa-check pd-r-8" />}
                            onClick={() => this.handleSubmit(true)}
                        >
                            Tạo Phiếu & Xác Nhận
                        </Button>
                    }
                    <Button key="submit"
                        className="sm-mg-t-8"
                        type="primary"
                        tabIndex={11}
                        disabled={loading || form.amount === undefined || form.amount === null || !form.memo}
                        icon={<i className="fa-solid fa-check pd-r-8" />}
                        onClick={() => this.handleSubmit()}
                    >Tạo phiếu</Button>
                </>
                }
                handleCancel={this.handleCancel}
            >
                <Form labelCol={{ span: 6 }} labelAlign="left" ref={this.formRef}>
                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Thông tin giao dịch</div>
                    <Form.Item label={'Loại phiếu thu'}>
                        <Select
                            value={form.type}
                            placeholder={"Vui lòng chọn loại phiếu thu"}
                            onChange={this.handleChangeSelect.bind(this, 'type')}
                        >
                            <Select.Option value={'ORDER'}>Thanh toán cho đơn (ORDER)</Select.Option>
                            <Select.Option value={'CUSTOMER'}>Nạp/Rút tiền (CUSTOMER)</Select.Option>
                        </Select>
                    </Form.Item>

                    {
                        form.type === 'ORDER' && <>
                            <Form.Item label={'Mã thanh toán'} required>
                                <Input
                                    value={form.orderCode}
                                    onChange={this.handleChangeInput.bind(this, "orderCode")}
                                    onPressEnter={() => this.handleSubmit()}
                                    placeholder="Vui lòng nhập mã thanh toán"
                                />
                            </Form.Item>
                        </>
                    }
                    {
                        form.type === 'ORDER' ? <>
                            <Form.Item label="Hệ thống" required>
                                <Select
                                    tabIndex={7}
                                    allowClear
                                    disabled={!accountProject.length}
                                    className={"!w-1/3"}
                                    placeholder={"Vui lòng chọn hệ thống"}
                                    value={this.state.deliveryNoteProject}
                                    onChange={(value) => this.handleChangeProject(value, true)}
                                >
                                    {projects
                                        .filter((item) => accountProject.includes(item.code))
                                        .map((item: ProjectDomain) => (
                                            <Select.Option key={item.code} value={item.code}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Mã phiếu xuất">
                                {/* {
                                    loadingDeliveryNotes ? <Select loading={loadingDeliveryNotes} className="!w-1/2" disabled placeholder={'Nhập mã phiếu xuất'}  value={}/> : */}
                                <Spin spinning={loadingDeliveryNotes}>
                                    <AutoComplete
                                        className={"[>.ant-select-selector]:rounded-r-lg w-2/3"}
                                        filterOption={false}
                                        value={form.deliveryNote}
                                        showSearch={true}
                                        onChange={this.handleChangeSelect.bind(this, "deliveryNote")}
                                        onSearch={lodash.debounce(this.handleSearchDeliveryNote, 1000)}
                                        placeholder={'Nhập mã phiếu xuất'}
                                        options={this.state.deliveryNotes.map((ele) => ({
                                            value: ele.code
                                        }))}
                                        disabled={!this.state.deliveryNoteProject}
                                    />
                                </Spin>

                                {/* } */}
                            </Form.Item>
                        </> : <>
                            <Form.Item label={form.type === 'ORDER' ? 'Hệ thống/Mã phiếu xuất' : 'Mã phiếu xuất'} className="!w-full">
                                <div className="!w-full flex gap-2">
                                    <Select
                                        style={{ width: `50%` }}
                                        tabIndex={7}
                                        allowClear
                                        disabled={!accountProject.length}
                                        className={"!w-1/3"}
                                        placeholder={"Vui lòng chọn hệ thống"}
                                        value={this.state.deliveryNoteProject}
                                        onChange={(value) => this.handleChangeProject(value, true)}
                                    >
                                        {projects
                                            .filter((item) => accountProject.includes(item.code))
                                            .map((item: ProjectDomain) => (
                                                <Select.Option key={item.code} value={item.code}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                    <div className="w-1/2 width-50pc">
                                        {/* <Spin spinning={loadingDeliveryNotes}> */}
                                        <AutoComplete
                                            className={""}
                                            filterOption={false}
                                            value={form.deliveryNote}
                                            showSearch={true}
                                            onChange={this.handleChangeSelect.bind(this, "deliveryNote")}
                                            onSearch={lodash.debounce(this.handleSearchDeliveryNote, 1000)}
                                            placeholder={'Nhập mã phiếu xuất'}
                                            options={this.state.deliveryNotes.map((ele) => ({
                                                value: ele.code
                                            }))}
                                            disabled={!this.state.deliveryNoteProject}
                                        />
                                        {/* </Spin> */}
                                    </div>
                                </div>
                            </Form.Item>
                        </>
                    }

                    <Form.Item label={"Mã bút toán"}>
                        <Input
                            disabled={accountType === "CASH" || loading}
                            tabIndex={1}
                            autoFocus={accountType !== "CASH"}
                            min={0}
                            className={"width-100pc"}
                            value={form.ref}
                            onChange={this.handleChangeInput.bind(this, "ref")}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder="Vui lòng nhập mã bút toán"
                        />
                    </Form.Item>

                    <Form.Item
                        label={`Số tiền (${lodash.get(account, "currency.code")})`}
                        name="amount"
                        rules={[{ required: true, message: "Số tiền không được để trống" }]}
                    >
                        <InputNumber
                            tabIndex={2}
                            autoFocus={accountType === "CASH"}
                            min={0}
                            className={"width-100pc"}
                            formatter={(value) => {
                                if (!value) {
                                    return ''
                                }

                                return currency === "VND"
                                    ? Utils.currencyFormat(value, "0,0")
                                    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                            value={form.amount}
                            disabled={loading}
                            onChange={this.handleChangeNumber.bind(this, "amount")}
                            onPressEnter={() => this.handleSubmit()}
                            placeholder={`Vui lòng nhập số tiền (${lodash.get(account, "currency.code")})`}
                        />
                    </Form.Item>

                    <Form.Item label={"Thời gian giao dịch"}>
                        <DatePicker
                            tabIndex={3}
                            className={"width-100pc"}
                            disabled={loading}
                            format={"DD/MM/YYYY HH:mm:ss"}
                            value={form.timestamp ? moment(form.timestamp) : null}
                            onChange={this.handleChangeDatePicker.bind(this, "timestamp")}
                            disabledDate={(current) => current && current > moment()}
                            placeholder="Vui lòng chọn thời gian giao dịch"
                            showTime
                        />
                    </Form.Item>

                    <Form.Item
                        label={"Nội dung giao dịch"}
                        name="memo"
                        rules={[{ required: true, message: "Nội dung giao dịch không được để trống" }]}
                    >
                        <Input
                            tabIndex={4}
                            value={form.memo}
                            disabled={loading}
                            placeholder="Vui lòng nhập nội dung giao dịch"
                            onChange={this.handleChangeInput.bind(this, "memo")}
                            onPressEnter={() => this.handleSubmit()}
                        />
                    </Form.Item>

                    <Form.Item label={"Ghi chú"}>
                        <Input.TextArea
                            tabIndex={5}
                            disabled={loading}
                            value={form.remark}
                            placeholder="Vui lòng nhập ghi chú"
                            onChange={this.handleChangeInput.bind(this, "remark")}
                        />
                    </Form.Item>

                    <div className={"mg-bt-12 font-medium fsz-16px capitalize"}>Người nộp tiền</div>

                    <Row gutter={12} className={"mg-bt-10"}>
                        <Col span={2}>
                            <Radio
                                disabled={!accountProject.length}
                                tabIndex={6}
                                value={"system"}
                                checked={payerType === "system"}
                                className="pd-l-8"
                                onChange={this.handleChangePayerType}
                            />
                        </Col>

                        <Col span={11}>
                            <Select
                                tabIndex={7}
                                allowClear
                                disabled={!accountProject.length || payerType !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng chọn hệ thống"}
                                value={project}
                                onChange={(value) => this.handleChangeProject(value)}
                            >
                                {projects
                                    .filter((item) => accountProject.includes(item.code))
                                    .map((item: ProjectDomain) => (
                                        <Select.Option key={item.code} value={item.code}>
                                            {item.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>

                        <Col span={11}>
                            <AutoComplete
                                tabIndex={8}
                                allowClear
                                disabled={!accountProject.length || payerType !== "system"}
                                className={"width-100pc"}
                                placeholder={"Vui lòng nhập Username/id"}
                                filterOption={false}
                                value={form.payerRef}
                                showSearch={true}
                                onChange={this.handleChangeSelect.bind(this, "payerRef")}
                                onSearch={lodash.debounce(this.handleSearchStaff, 1000)}
                            >
                                {customers.map((item: CustomerDomain) => (
                                    <AutoComplete.Option key={item.username} value={item.username}>
                                        {item.fullname} ({item.username})
                                    </AutoComplete.Option>
                                ))}
                            </AutoComplete>
                        </Col>
                    </Row>

                    <Row gutter={12}>
                        <Col span={2}>
                            <Radio
                                tabIndex={9}
                                value={"person"}
                                checked={payerType === "person"}
                                className="pd-l-8"
                                onChange={this.handleChangePayerType}
                            />
                        </Col>
                        <Col span={22}>
                            <Input
                                tabIndex={10}
                                disabled={payerType !== "person" || loading}
                                placeholder={"Vui lòng nhập người nộp tiền"}
                                value={form.payer}
                                onChange={this.handleChangeInput.bind(this, "payer")}
                                onPressEnter={() => this.handleSubmit()}
                            />
                        </Col>
                    </Row>
                </Form>
            </DraggableModal>
        )
    }
}

export default ModalReceiptVoucherCreate
