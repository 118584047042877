export const ACCOUNT_TYPES = [
    { key: 'CASH', text: 'Tiền mặt' },
    { key: 'BANK', text: 'Ngân hàng' },
]

export const PARTICIPANT_TYPES = [
    { key: 'inside', text: 'Trong hệ thống' },
    { key: 'outside', text: 'Ngoài hệ thống' },
]

export const MESSAGE_STATUSES = {
    NEW: 'Vừa nhận được từ app',
    SUCCESS: 'Hoàn thành',
    REJECTED: 'Không chấp nhận',
    FAILED: 'SMS Failed chưa tạo phiếu',
    RESOLVED: 'SMS Failed đã tạo phiếu',
}

export const MESSAGE_STATUS_TOOLTIP = { ...MESSAGE_STATUSES, RESOLVED: 'Đã xử lý bằng cách tạo phiếu thủ công' }

export const DEVICE_STATUSES = [
    { code: 'PAIRING', name: 'Chờ kết nối', color: '#FDB924' },
    { code: 'CONNECTED', name: 'Đã kết nối', color: '#4DD69C' },
    { code: 'DISABLED', name: 'Ngắt kết nối', color: '#FF4559' },
]

// Quyền theo user
export const PERMISSIONS = {
    ACCOUNT_CREATE: 'account:create',
    ROLE_VIEW: 'role:view',
    ROLE_CREATE: 'role:create',
    ROLE_UPDATE: 'role:update',
    ROLE_PERMISSION_VIEW: 'role:permission:view',
    PERMISSION_GRANT: 'permission:grant',
    DEVICE_CREATE: 'device:create',
    DEVICE_VIEW: 'device:view',
    DEVICE_CONFIG_VIEW: 'device:config:view',
    SIM_CONFIG_CREATE: 'sim:config:create',
    SIM_CONFIG_UPDATE: 'sim:config:update',
    MEMO_CONFIG: 'memo:config:create',
    STAFF_VIEW: 'staff:view',
    PAYMENT_REASON_VIEW: 'payment-reason:view',
    PAYMENT_REASON_CREATE: 'payment-reason:create',
    PAYMENT_REASON_UPDATE: 'payment-reason:update',
    DEVICE_DELETE: 'device:delete',
    TRANSACTION_LOG_VIEW: 'transaction:log-view',
    MEMO_VIEW: 'memo:config:view',
    NO_ACCOUNT_SMS_VIEW: 'device:view-noaccount-sms',
}

export const PAYMENT_VOUCHER_WARNING = {
    DUPLICATE_PAYMENT_REASON: 'duplicate:payment-reason',
}

export const ACCOUNT_EVENT = {
    ADD_PROJECT: 'ADD_PROJECT',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
}

export const FILTER_TYPES = [
    { code: 'between', name: 'Khoảng' },
    { code: 'eq', name: '=' },
    { code: 'gte', name: '>=' },
    { code: 'lte', name: '<=' },
]

export const TRANSACTION_STATUS_OPTIONS = [
    { value: 'REVIEWED', label: 'Đã duyệt' },
    { value: 'PENDING', label: 'Chờ duyệt' },
    { value: 'REJECTED', label: 'Đã hủy' },
]

export const REVIEW_STATUSES_CODE = [
    { code: 'PENDING', name: 'Chờ duyệt' },
    { code: 'REVIEWED', name: 'Đã duyệt' },
]

export const STATUSES_CODE = [
    { code: 'PENDING', name: 'Chờ xác nhận' },
    { code: 'APPROVED', name: 'Đã xác nhận' },
    { code: 'REJECTED', name: 'Hủy' },
]

// Quyền theo tài khoản quỹ
export const ACCOUNT_PERMISSIONS = {
    SUB_TRANSACTION_DELETE: 'sub-transaction:delete',
    TRANSACTION_REVIEW: 'transaction:review',
    TRANSACTION_RECHARGE: 'transaction:recharge',
    SUB_TRANSACTION_EDIT: 'sub-transaction:edit',
    TRANSACTION_REJECT: 'transaction:reject',
    RECEIPT_VOUCHER_REVIEW: 'receipt-voucher:review',
    PAYMENT_VOUCHER_REVIEW: 'payment-voucher:review',
    TRANSACTION_UPDATE: 'transaction:update',
    MESSAGE_UPDATE: 'message:update',
    ACCOUNT_SETTLEMENT_VIEW: 'account-settlement:view',
    TRANSACTION_RETRY_SYNCHRONIZE: 'transaction:retry-synchronize',
    MESSAGE_RETRY_ANALYSE: 'message:retry-analyse',
    ACCOUNT_LOG_VIEW: 'account-log:view',
    ACCOUNT_OWNER_VIEW: 'account-owner:view',
    ACCOUNT_OWNER_DELETE: 'account-owner:delete',
    MESSAGE_LOG_VIEW: 'message-log:view',
    TRANSACTION_LOG_VIEW: 'transaction-log:view',
    PAYMENT_VOUCHER_LOG_VIEW: 'payment-voucher-log:view',
    RECEIPT_VOUCHER_LOG_VIEW: 'receipt-voucher-log:view',
    ACCOUNT_OWNER_CREATE: 'account-owner:create',
    PRIVILEGE_VIEW: 'privilege:view',
    PAYMENT_VOUCHER_APPROVE: 'payment-voucher:approve',
    TRANSACTION_VIEW: 'transaction:view',
    RECEIPT_VOUCHER_UPDATE: 'receipt-voucher:update',
    PRIVILEGE_UPDATE: 'privilege:update',
    RECEIPT_VOUCHER_REJECT: 'receipt-voucher:reject',
    PAYMENT_VOUCHER_UPDATE: 'payment-voucher:update',
    MESSAGE_VIEW: 'message:view',
    PRIVILEGE_CREATE: 'privilege:create',
    PAYMENT_VOUCHER_CREATE: 'payment-voucher:create',
    PAYMENT_VOUCHER_REJECT: 'payment-voucher:reject',
    PAYMENT_VOUCHER_VIEW: 'payment-voucher:view',
    ACCOUNT_VIEW: 'account:view',
    RECEIPT_VOUCHER_CREATE: 'receipt-voucher:create',
    ACCOUNT_ENQUIRE_BALANCE: 'account:enquire-balance',
    ACCOUNT_SETTLEMENT: 'account:settlement',
    PRIVILEGE_DELETE: 'privilege:delete',
    RECEIPT_VOUCHER_VIEW: 'receipt-voucher:view',
    RECEIPT_VOUCHER_APPROVE: 'receipt-voucher:approve',
    TRANSACTION_EXPORT: 'transaction:export',
}

export const TRANSACTION_TYPES: { [key: string]: string } = {
    ORDER: 'Thanh toán cho đơn',
    CUSTOMER: 'Nạp/Rút tiền',
}
