import lodash from 'lodash'
import moment from 'moment'
import { Component } from 'react'
import CommonTable from '../../Component/CommonTable'
import MemoDomain from '../../Domain/MemoDomain'
import { Tooltip } from 'antd'
import MemoApi from '../../Api/MemoApi'
import { NotificationCommon } from '../../Component/Notification'
import { ModalConfirm } from '../../Component/ModalCommon/ModalConfirm'
import SecurityService from '../../Util/SecurityService'
import { PERMISSIONS } from '../../Util/Constants'

interface Props {
    loading?: boolean
    items: Array<MemoDomain>
    total: number
    pageSize: number
    currentPage: number
    onChangePage: (page: number, pageSize?: number) => void
    onRefresh: () => void
}

interface State {
    projects: any
    columns: any
    isDeleting: boolean
}

const typeMapping: { [key in string]: string } = {
    CUSTOMER: 'Khách hàng',
    ORDER: 'Đơn hàng',
}

class DataList extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const columns: any = [
            {
                title: 'Hệ thống',
                key: 'project',
                dataIndex: 'project',
            },
            {
                title: 'Cú pháp',
                key: 'placeholder',
                render: (row: any) => <div style={{ minWidth: 50, maxWidth: 180 }}>{row.placeholder ? row.placeholder : '---'}</div>,
            },
            {
                title: 'Tiền tố',
                key: 'prefixUsername',
                render: (row: any) => <div style={{ minWidth: 50, maxWidth: 100 }}>{row.prefixUsername ? row.prefixUsername : '---'}</div>,
            },
            {
                title: 'Kiểu cú pháp',
                key: 'type',
                dataIndex: 'type',
                width: 150,
                render: (type: string) => typeMapping[type],
            },
            {
                title: 'Ngày tạo',
                key: 'createdAt',
                render: (row: any) => moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            },
            {
                title: 'Người tạo',
                key: 'createdBy',
                dataIndex: 'createdBy',
                render: (value: any) => <div style={{ minWidth: 50 }}>{value ? value : '--'}</div>,
            },
        ]

        if (SecurityService.can(PERMISSIONS.MEMO_CONFIG)) {
            columns.push({
                title: 'Thao tác',
                key: 'action',
                dataIndex: 'id',
                align: 'center',
                render: (id: any) => (
                    <div style={{ minWidth: 80 }}>
                        <Tooltip title={'Xoá'}>
                            <i
                                className={'fa-regular fa-trash pointer text-danger'}
                                onClick={() => this.handleRemoveRole(id)}
                            />
                        </Tooltip>
                    </div>
                ),
            })
        }

        this.state = {
            projects: [],
            columns,
            isDeleting: false,
        }
    }

    handleRemoveRole = (id: string) => {
        if (this.state.isDeleting) return

        ModalConfirm.confirm({
            title: 'Xác nhận',
            content: 'Bạn chắn chắn muốn xóa cú pháp này?',
            onOk: () => {
                this.setState({
                    isDeleting: true,
                })
                MemoApi.delete(id)
                    .then(() => {
                        NotificationCommon.success({ message: 'Xóa cú pháp thành công' })
                        this.props.onRefresh()
                    })
                    .catch(NotificationCommon.fetchError)
                    .finally(() => this.setState({ isDeleting: false }))
            },
        })
    }

    handleChangePage = (page: number, pageSize?: number) => {
        this.props.onChangePage(page, pageSize)
        window.scroll({ top: 0, behavior: 'smooth' })
    }

    render() {
        const { loading, total, pageSize, items, currentPage } = this.props
        const { columns } = this.state
        return (
            <div className={''}>
                <CommonTable
                    className={''}
                    columns={columns as any}
                    dataSource={items}
                    loading={loading}
                    rowKey={() => lodash.uniqueId()}
                    pagination={{
                        current: currentPage,
                        total: total,
                        pageSize: pageSize,
                        onChange: this.handleChangePage,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 25, 50, 100],
                        showLessItems: true,
                    }}
                    emptyText="Danh sách cú pháp đang trống"
                    rowClassName={'memo-record'}
                    scroll={{ y: window.innerHeight - 240, x: 768 }}
                />
            </div>
        )
    }
}

export default DataList
