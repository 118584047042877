import {Badge, Button, Modal} from "antd";
import {AxiosError} from "axios";
import lodash from "lodash";
import ReceiptVoucherApi from "../../../Api/ReceiptVoucher";
import ReceiptVoucherCreateCommand from "../../../Command/ReceiptVoucherCreateCommand";
import DefaultComponent from "../../../Component/DefaultComponent";
import DefaultLayout from "../../../Component/Layout/Default";
import ModalReceiptVoucherCreate from "../../../Component/ModalReceiptVoucherCreate";
import { NotificationCommon } from "../../../Component/Notification";
import AccountBoxStatisticConsumer from "../../../Consumer/AccountBoxStatisticConsumer";
import {AppContext} from "../../../Context/AppContext";
import ReceiptVoucherDomain, { ReceiptVoucherDetails } from "../../../Domain/ReceiptVoucherDomain";
import Utils from "../../../Util/Utils";
import DataList from "./DataList";
import FilterForm from "./FilterForm";

import './style.scss'
import ProjectApi from "../../../Api/ProjectApi";
import ProjectDomain from "../../../Domain/ProjectDomain";
import React, {createRef, RefObject} from "react";
import {DraggableModalRef} from "../../../Component/DraggableModal";
import {ACCOUNT_PERMISSIONS} from "../../../Util/Constants";
import SecurityService from "../../../Util/SecurityService";
interface State {
    loading: boolean,
    isShowModalCreate: boolean,
    items: Array<ReceiptVoucherDomain>,
    total: number,
    pageSize: number,
    currentPage: number,
    account: any,
    projects:Array<ProjectDomain>,
	voucherClone?: ReceiptVoucherDetails
}

class ReceiptVoucherList extends DefaultComponent<any, State> {

    static contextType = AppContext;
    createReceiptVoucherRef: RefObject<DraggableModalRef> = createRef()

    state = {
        account: this.context.state.account,
        loading: false,
        isShowModalCreate: false,
        receiptVouchers: [],
        pagination: {
            total: 0,
            pageSize: 0,
            currentPage: 1
        },
        suggestStaffs: [],
        projects:[],
        voucherClone: undefined
    }

    componentDidMount() {
        this.fetchReceiptVouchers({
            ...this.getQueryFromLocation()
        }).then();
        this.fetchProjects();
    }

    componentDidUpdate(prevProps: any) {
        if (
            JSON.stringify(this.props.location.search) !== JSON.stringify(prevProps.location.search)
            || JSON.stringify(this.props.match.params) !== JSON.stringify(prevProps.match.params)
        ) {
            this.fetchReceiptVouchers({
                ...this.getQueryFromLocation()
            }).then();
        }
    }

    fetchReceiptVouchers = async (filter: any = {}) => {
        const {account} = this.state;
        if (account.code) {
            this.setState({loading: true})
            try {
                filter = {
                    offset: 0,
                    limit: 25,
                    sort: 'timestamp:desc',
                    ...filter,
                }

                const receiptVoucher = await ReceiptVoucherApi.getReceiptVouchersByAccount(lodash.get(account, 'code'), filter)
                this.setState({
                    receiptVouchers: receiptVoucher.data,
                    pagination: {
                        pageCount: receiptVoucher.headers["x-page-count"],
                        currentPage: Number(receiptVoucher.headers["x-page-number"]) + 1,
                        pageSize: receiptVoucher.headers["x-page-size"],
                        total: receiptVoucher.headers["x-total-count"]
                    }
                })
            } catch (error: any) {}

            this.setState({loading: false});
        }
    };

    fetchProjects = () => {
        ProjectApi.filter({offset: 0, limit: 1000}, true)
            .then(response => {
                this.setState({ projects: response.data} )
            })
    }

    showModalCreate = (voucherClone?: ReceiptVoucherDetails) => {
        this.setState({isShowModalCreate: true, voucherClone});
    }

    handleModalCreateVisibleChange = (visible: boolean) => {
        if (this.createReceiptVoucherRef.current && visible){
            this.createReceiptVoucherRef.current?.setVisible(true)
            return
        }

        this.setState({isShowModalCreate: visible});
    };

    onChangePage = (page: number, pageSize: any) => {
        this.pushCleanQueryToHistory({
            ...this.getQueryFromLocation(),
            page: page,
            limit: pageSize,
            offset: (page - 1) * pageSize
        })
    }

    handleCreateReceiptVoucher = (form: ReceiptVoucherCreateCommand) => {
        const {account} = this.state;
        this.setState({loading: true});
        ReceiptVoucherApi.create(form)
            .then(() => {
                NotificationCommon.success({
                    message: "Tạo phiếu thu thành công"
                });
                this.setState({isShowModalCreate: false});

                if (Object.keys(this.getQueryFromLocation()).length) {
                    this.pushCleanQueryToHistory({});
                } else {
                    this.fetchReceiptVouchers().then();
                }

                Utils.getAppContext(this).func.fetchAccountAndUpdateState(account.code);
            })
            .catch((error: AxiosError) => {
                if (lodash.get(error, 'response.status') === 400) {
                    if (lodash.get(error, 'response.data.title') === 'receipt_ineligible') {
                        NotificationCommon.error({
                            message: 'Thời gian giao dịch không hợp lệ do đã chốt sổ'
                        })
                    } 
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist') {
                        NotificationCommon.error({
                            message: `Mã bút toán đã tồn tại trong giao dịch ${lodash.get(error.response, 'data.transactionCode')}`
                        })
                    } 
                    else if (lodash.get(error, 'response.data.title') === 'ref_exist_pending_voucher') {
                        this.confirmForceCreateVoucher(form, error);
                    } 
                    else if (lodash.get(error, "response.data.title") === "invalid_customer") {
						NotificationCommon.error({
							message: "Sai định dạng username",
						})
					}
                    else if (lodash.get(error, "response.data.title") === "customer_not_exist") {
                        let details = lodash.get(error,'response.data.detail').split(/'/);
                        NotificationCommon.error({
                            message: <span>Không tồn tại username <span className="bold">{details[1]}</span> trong hệ thống <span className={'bold'}>{details[3]}</span>.</span>,
                        })
                    }
                    else {
                        NotificationCommon.error({
                            message: "Có lỗi. Vui lòng kiểm tra lại thông tin trên form"
                        })
                    }
                }
            })
            .finally(() => {
                this.setState({loading: false});
            })
    }

    confirmForceCreateVoucher = (form: ReceiptVoucherCreateCommand, error: AxiosError) => {
        const voucherCodes = lodash.get(error.response, 'data.voucherCodes');
        Modal.confirm({
            title: `Mã bút toán đã nằm trong phiếu thu ${voucherCodes.join(',')}. Bạn có muốn tiếp tục tạo phiếu không?`,
            okText: "Xác nhận",
            cancelText: "Bỏ qua",
            onOk: () => {
                this.handleCreateReceiptVoucher({
                    ...form,
                    force: true
                })
            }
        })
    }

    render() {
        const {receiptVouchers, pagination, isShowModalCreate, loading, account,projects} = this.state;
        const voucherClone: any = this.state.voucherClone
        return (
            <DefaultLayout
                {...this.props}
                title={`${account.name} - Danh sách phiếu thu`}
                breadcrumb={[{title: 'Danh sách phiếu thu'}]}
            >
                <div className="main-content receipt-voucher">
                    <div className="white-box px-12 py-12">
                        <AccountBoxStatisticConsumer />
                    </div>

                    <div className="white-box py-12 px-12">
						<FilterForm {...this.props} />
                    </div>

                    <div className="white-box py-12 px-12 ">
                        <div className="table-container">
							<div className="table-header space-between mg-bt-12">
								<div className="align-center font-medium fsz-16px capitalize">
                                    Danh sách phiếu thu <Badge count={pagination.total} showZero overflowCount={999999} className="pd-l-8" />
								</div>
								<div>
                                    {SecurityService.allowTo(ACCOUNT_PERMISSIONS.RECEIPT_VOUCHER_CREATE) && <>
                                        <Button disabled={!account.code} type={'primary'} onClick={() => this.showModalCreate()} icon={<i className="fa-regular fa-plus pd-r-8" />}>Tạo phiếu thu</Button>
                                    </>}
                                </div>
							</div>
                        <DataList
                            loading={loading}
                            items={receiptVouchers}
                            total={pagination.total}
                            pageSize={pagination.pageSize}
                            currentPage={pagination.currentPage}
                            onChangePage={this.onChangePage}
                            cloneVoucher={this.showModalCreate}
                            projects={projects}
                        />
                        </div>
                    </div>
                </div>

                {isShowModalCreate && (
                    <ModalReceiptVoucherCreate
                        modalRef={this.createReceiptVoucherRef}
                        loading={loading}
						voucherCloneCode={voucherClone?.code}
                        onVisibleChange={this.handleModalCreateVisibleChange}
                        onSubmit={this.handleCreateReceiptVoucher}
                    />
                )}

            </DefaultLayout>
        );
    }
}

export default ReceiptVoucherList;
