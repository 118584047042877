import { Tooltip, Button } from "antd";
import { get } from "lodash";
import TransactionsDomain from "../../../Domain/TransactionDomain";

export function getFailedReason(reason: string, row: TransactionsDomain): { reason: any; guide: any } {
  if (reason.includes('Participant ref value is null or empty')) {
      return {
          reason: 'Trong giao dịch chưa có thông tin người nhận/nộp',
          guide: 'Vui lòng cập nhật thông tin người nhận/nộp trên giao dịch sau đó bấm đồng bộ lại',
      }
  }

  if (reason.includes('Invalid participant ref format')) {
      return {
          reason: 'Username không hợp lệ',
          guide: 'Liên hệ kỹ thuật để được hỗ trợ',
      }
  }

  if (reason.includes('Payment voucher code is null or empty')) {
      return {
          reason: 'Không thể đồng bộ giao dịch trừ tiền từ SMS',
          guide: 'Không cho phép truy thu tài chính khách từ những giao dịch phát sinh tự động',
      }
  }

  if (reason.includes('Transaction type') && reason.includes('is invalid to be created transaction')) {
      return {
          reason: 'Không cho phép đồng bộ giao dịch khởi tạo quỹ',
          guide: 'Liên hệ kỹ thuật để được hỗ trợ',
      }
  }

  if (reason.includes('Transaction') && reason.includes('not sync because it is not reviewed')) {
      return {
          reason: 'Giao dịch chưa được duyệt',
          guide: 'Vui lòng duyệt giao dịch để nạp tài chính cho khách',
      }
  }

  if (reason.includes('Project') && reason.includes(`isn't connected in account`)) {
      const project = reason.split(`Project '`)[1]?.split(`' isn't`)[0]

      return {
          reason: <>Quỹ chưa được liên kết với hệ thống {!!project && project}</>,
          guide: <>Kết nối hệ thống {project && <span className="font-medium">{project}</span>} với tài khoản quỹ, sau đó bấm đồng bộ lại</>,
      }
  }

  if (reason.includes('nested exception is io.netty.handler.timeout.ReadTimeoutException')) {
      return {
          reason: 'Hệ thống không phản hồi',
          guide: 'Liên hệ kỹ thuật để được hỗ trợ',
      }
  }

  if (reason.includes('customer_not_found')) {
      const username = get(row, 'txLog.customer')
      const system = get(row, 'txLog.project')

      return {
          reason: (
              <>
                  Username {username} không tồn tại trên hệ thống {system}
              </>
          ),
          guide: (
              <>
                  Vui lòng kiểm tra lại thông tin username của khách hàng trên hệ thống <span className="font-medium">{system}</span>
              </>
          ),
      }
  }

  return { reason: 'không xác định', guide: 'Liên hệ kỹ thuật để được hỗ trợ' }
}

export const renderRetry = (_: any, onClick: () => void) => (
  <Tooltip
      title="Đồng bộ lại"
      placement="left"
      color={'#FDB924'}>
      <Button
          onClick={onClick}
          type="primary"
          className="yellow-btn icon-btn">
          <i className="fa-solid fa-rotate-left" />
      </Button>
  </Tooltip>
)

export const renderEdit = (_: any, onClick: () => void) => (
  <Tooltip
      title="Cập nhật giao dịch"
      placement="left"
      color="#4DD69C">
      <Button
          className={'mg-t-8 light-green-btn icon-btn'}
          onClick={onClick}
          type="primary">
          <i className="fa-solid fa-pen-to-square" />
      </Button>
  </Tooltip>
)

export const renderEditNote = (showTooltip: boolean = true, onClick: () => void) =>
  showTooltip ? (
      <Tooltip
          title={'Cập nhật ghi chú'}
          placement="left"
          color="#3340B6">
          <Button
              className={'blue-btn icon-btn mg-t-8'}
              type="primary"
              onClick={onClick}>
              <i className="fa-solid fa-note" />
          </Button>
      </Tooltip>
  ) : (
      <span onClick={onClick}>
          <i className="fa-regular fa-note" />
          Cập nhật ghi chú
      </span>
  )

export const renderClone = (showTooltip: boolean = true, onClick: () => void) =>
  showTooltip ? (
      <Tooltip
          title="Nhân bản phiếu"
          placement="left">
          <Button
              onClick={onClick}
              type="primary"
              className="icon-btn mg-t-8">
              <i className="fa-solid fa-clone" />
          </Button>
      </Tooltip>
  ) : (
      <span onClick={onClick}>
          <i className="fa-regular fa-clone" />
          Nhân bản phiếu
      </span>
  )

export const renderAddSub = (showTooltip: boolean = true, onClick: () => void) =>
  showTooltip ? (
      <Tooltip
          title="Tạo giao dịch con"
          placement="left"
          color="#339DFF">
          <Button
              className={'blue-btn icon-btn mg-t-8'}
              type="primary"
              onClick={onClick}>
              <i className="fa-solid fa-plus" />
          </Button>
      </Tooltip>
  ) : (
      <span onClick={onClick}>
          <i className="fa-regular fa-plus" />
          Tạo giao dịch con
      </span>
  )

  export const renderRejectPayment = (showTooltip: boolean = true, onClick: () => void) =>
  showTooltip ? (
      <Tooltip
          title="Hủy giao dịch"
          placement="left"
          color="#FF4559">
          <Button
              className={'danger-btn icon-btn mg-t-8'}
              onClick={onClick}>
              <i className="fa-solid fa-circle-xmark"></i>
          </Button>
      </Tooltip>
  ) : (
      <span onClick={onClick}>
          <i className="fa-regular fa-circle-xmark"></i>
          Hủy giao dịch
      </span>
  )